import { render, staticRenderFns } from "./HomeAboutWestudy.vue?vue&type=template&id=71aa7b4f&scoped=true&"
import script from "./HomeAboutWestudy.vue?vue&type=script&lang=js&"
export * from "./HomeAboutWestudy.vue?vue&type=script&lang=js&"
import style0 from "./HomeAboutWestudy.vue?vue&type=style&index=0&id=71aa7b4f&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "71aa7b4f",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VSheet } from 'vuetify/lib/components/VSheet';
installComponents(component, {VSheet})
